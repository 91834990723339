import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  ViewChild
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Default} from '../../../interfaces/default';
import {City} from '../../../interfaces/city';
import {DefaultService} from '../../../services/default.service';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {UserInfo} from '../../../interfaces/user-info';
import {Direction, DirectionText} from '../../../interfaces/direction';
import {CarArticle} from '../../../interfaces/car-article';
import {ShoppingCarService} from '../../../services/shopping-car.service';
import {ArticleOrder} from '../../../interfaces/article-order';
import {TPV} from '../../../interfaces/tpv';
import {OrderResult} from '../../../interfaces/order-result';
import {CompanyConf} from '../../../interfaces/company-conf';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Country} from '../../../interfaces/country';
import {Province} from '../../../interfaces/province';
import {PriceRoute} from '../../../interfaces/price-route';
import {LocalStorageService} from '../../../services/local-storage.service';
import { timer } from 'rxjs';
import {FunctionsService} from '../../../services/functions.service';
import {SuperGroup} from '../../../interfaces/group';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { ICreateOrderRequest } from 'ngx-paypal';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnChanges {

  constructor(private defaultService: DefaultService, private sanitizer: DomSanitizer, private authService: AuthService,
              private shoppingCarService: ShoppingCarService, public datePipe: DatePipe, private functionsService: FunctionsService,
              private router: Router, private authStorageService: AuthStorageService, private modalService: NgbModal,
              private localStorageService: LocalStorageService, private _cdr: ChangeDetectorRef) {}

  @Input() userInfo: UserInfo;
  @Input() articles: Array<CarArticle>;
  @Input() totalPrice: number;
  @Input() minPrice: number;
  @Input() totalTransport: number;
  @Input() companyConf: CompanyConf;
  @Output() finishOrder = new EventEmitter<boolean>();
  @Output() returnShoppingCar = new EventEmitter<boolean>();
  @Output() getUserData = new EventEmitter<boolean>();
  @Output() setHomeDelivery = new EventEmitter<boolean>();
  @Input() countries: Array<Country>;
  @Input() usuMod: string;
  @Input() newUser: boolean;
  @Input() homeDelivery: boolean;
  @Input() superGroups: Array<SuperGroup>;
  @Input() selectedOrderSuperGroup: SuperGroup;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  provinces: Array<Province>;
  cities: Array<City>;
  cookieCp = true;
  errorDirection;
  showDirections = false;
  selectDirection = false;
  directions: Array<Direction> = [];
  selectedDirection: Direction;
  newDirection = false;
  days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  lettersDays = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];

  img;
  msgInfoDays;
  msgInfoHour;

  contactValid = false;
  contactUserInvalid = false;
  directionValid = false;

  showSuccessOrder = false;
  showNewRegister = false;

  contactForm = new FormGroup({
    name: new FormControl('', [Validators.required, this.validLetters()]),
    surnames: new FormControl('', [Validators.required, this.validLetters()]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{9}$')]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  directionForm = new FormGroup({
    country: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required, this.notDefaultOption()]),
    direction: new FormControl('', [Validators.required, this.validLetters()]),
    phoneNumber: new FormControl('', [Validators.pattern('^[0-9]{9}$')]),
  });

  deliveryDate = new FormGroup({
    deliveryDate: new FormControl(null),
  });

  observations = new FormGroup({
    commercialObservations: new FormControl('', this.validLetters()),
    observations: new FormControl('', this.validLetters()),
    referenceOrder: new FormControl('', this.validLetters()),
  });

  tpvParameters: TPV;
  isInRoute: number = 1;
  orderResult: OrderResult;

  date : any; // Para el filtro del calendario en empresas
  deliveryDateValid = true;
  dateStr: string;
  showDate: string;
  minDate =  new Date((new Date()).setDate((new Date()).getDate() + 1));
  maxDate =  new Date((new Date()).setDate((new Date()).getDate() + 30));

  storePickupDates: Array<Default> = [];
  selectedStorePickupDate: Default;
  selectPickUpDate = false;
  selectPickUpDateCalendar = false;
  editDirection = false;
  storePickup  = false;

  deliveryCalendarDays: string;

  continueAuth = false;

  userForm = new FormGroup({
    user: new FormControl('', [Validators.required]), // , this.userValidator()
    password: new FormControl('', [Validators.required, this.validLetters()]),
  });
  hide = true;
  isLoginFailed;
  lockUser;
  showSummaryArticles = false;

  scheduleHour;
  selectedSuperGroup = null;
  totalLumps = null;
  deliveryTime = null;
  today = new Date();
  selectedTodayDate = false;
  articuloRegalo: any = undefined;
  prepararRegalo = false;
  ruta: number | undefined = null;
  aceptoPoliticas = false;

  mostrarPaypal = false;
  public payPalConfig: any;
  public showPaypalButtons: boolean = false;

  ngOnInit(): void {
    if (this.companyConf.storePickup === false) {
      this.selectDirection = true;
    } else {
      const lettersDays = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];
      const nameDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
      if (this.companyConf.storePickupDays != null) {
        this.companyConf.storePickupDays.split(',').forEach( dayLetter => {
          const d = new Date();
          const pos = lettersDays.findIndex(x => x === dayLetter);
          if (pos === d.getDate()) {
            d.setDate(d.getDate() + 7);
          } else {
            d.setDate(d.getDate() + (pos + 7 - d.getDay()) % 7);
          }
          const storePickupDate = new Default(dayLetter, this.datePipe.transform(d, ' dd-MM-yyyy'));
          storePickupDate.value = nameDays[pos] + storePickupDate.value;
          this.storePickupDates.push(storePickupDate);
        });
        this.storePickupDates.sort((a, b) => {
          return <any>new Date(a.value.split(' ')[1]) - <any>new Date(b.value.split(' ')[1]);
        });
      }
    }

    if (this.selectedOrderSuperGroup != null) {
      this.selectedSuperGroup = this.selectedOrderSuperGroup.id;
    }

    if (this.companyConf.storePickUpSchedule != null) {
      this.scheduleHour = this.companyConf.storePickUpSchedule[0];
    }

    if (!this.homeDelivery) {
      this.selectPickUpDate = true;
      this.storePickup = true;
      this.selectDirection = false;
      this.editDirection = true;
    }

    if (this.companyConf.articuloRegalo != undefined && this.companyConf.articuloRegalo != '') {
      if (this.articles.findIndex(el => el.id == this.companyConf.articuloRegalo) != -1) {
        this.articuloRegalo = this.articles.find(el => el.id == this.companyConf.articuloRegalo);
        this.prepararRegalo = true;
      } else {
        this.shoppingCarService.articuloRegalo().subscribe(
          (articulo: any) => {
            this.articuloRegalo = articulo;
          }
        );
      }
    }


    if (this.companyConf.soloRecogidaTienda) {
      this.selectPickUpDate = true;
      this.selectPickUpDateCalendar = false;
      this.selectDirection = false;
      this.storePickup = false;
      this.setHomeDeliveryFn(false);
    } else if (this.userInfo != null) {
      this.loadUserInfo();
    } else {
      this.initialDirectionsValues();
    }

    if (this.companyConf.soloRecogidaTienda) {
      this.selectPickUpDate = true;
      this.storePickup = true;
      this.selectPickUpDateCalendar = false;
      this.selectDirection = false;
      this.setHomeDeliveryFn(false);
    } else {
      this.initialDirectionsValues();
    }

    this.pagarConPaypal("AbTuYD5NJ6HwYM8mBXlLcI6RtFtui3fMwvSoN4EQwHDM2mqWS6BzoVBeEKXDZKD3PmALUdsd0eTDxti0", 20.10)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.homeDelivery?.currentValue != null) {
      this.directionValid = false;
      if (!this.homeDelivery) {
        this.selectPickUpDate = true;
        this.storePickup = true;
        this.selectDirection = false;
        if (this.userInfo != null) {
           this.editDirection = true;
        }
        this.setHomeDeliveryFn(false);
      } else {
        this.selectDirection = true;
        this.selectPickUpDate = false;
        this.storePickup = false;
        this.selectedStorePickupDate = null;


        if (this.userInfo != null) {
          this.selectDirection = false;
          this.editDirection = false;
          this.selectPostalDirection();
        }
        this.setHomeDeliveryFn(true);
      }
    }
  }

  loadUserInfo() {
    this.newUser = false;
    if (!this.companyConf.puntosRecogida) {
      this.isInRoute = this.userInfo.isInRoute;
    } else {
      this.isInRoute = 0;
    }

    if (this.companyConf.daysMakeOrder != null) {
      this.maxDate =  new Date((new Date()).setDate((new Date()).getDate() + this.companyConf.daysMakeOrder));
    }

    if (this.companyConf.maxHourOrder != null) {
      this.maxDate =  new Date((new Date()).setDate((new Date()).getDate() + this.companyConf.daysMakeOrder));
      const actualHour = new Date().getHours();
      if (this.companyConf.maxHourOrder > actualHour) {
        this.minDate =  new Date((new Date()).setDate((new Date()).getDate()));
      }
    }

    if (!this.companyConf.soloRecogidaTienda) {
      this.getDirections();
    }

  }

  daysStorePickupCalendar: (date: Date | null) => boolean = (d: Date | null) => {
    const d1 = this.datePipe.transform(d, 'yyyy-MM-dd');
    return this.companyConf.storePickUpDates.find(el => el === d1) != null;
  }



  calendarioEntrega: (date: Date | null) => boolean = (d: Date | null) => {
    const id = this.directionForm.controls['city'].value;
    if (id !== 'default') {
      const pos = this.cities.findIndex(x => x.id === id);
      if (pos != -1) {
        const selectDays = this.cities[pos].deliveryDays.split(',');
        return this.calendarioDias(d, selectDays);
      }
    }

    return false;
  }

  calendarioEntregaUsuario: (date: Date | null) => boolean = (d: Date | null) => {
    if (this.selectedDirection != null) {
      const selectDays = this.selectedDirection.deliveryDays.split(',');
      return this.calendarioDias(d, selectDays);
    } else if (this.userInfo != null) {
      const selectDays = this.userInfo.deliveryDays.split(',');
      return this.calendarioDias(d, selectDays);
    }

    return false;
  }

  calendarioRecogidaEnTienda: (date: Date | null) => boolean = (d: Date | null) => {
    const selectDays = [];
    this.storePickupDates.forEach(el => {selectDays.push(el.id)});
    return this.calendarioDias(d, selectDays);
  }

  calendarioRecogidaRuta: (date: Date | null) => boolean = (d: Date | null) => {
    let selectDays = [];
    let pos = this.companyConf.rutasRecogida.findIndex(el => el.id == this.ruta);
    if (pos != undefined) {
      selectDays = this.companyConf.rutasRecogida[pos].dias.trim().split(',');
    }

    return this.calendarioDias(d, selectDays);
  }

  calendarioDias(d: Date | null, diasStr: Array<string>): boolean {
    const d1 = this.datePipe.transform(d, 'yyyy-MM-dd');
    let dates = [];
    diasStr.forEach(el => {
      let dayNumber = 1;
      switch (el) {
        case 'L':
          dayNumber = 1;
          break;
        case 'M':
          dayNumber = 2;
          break;
        case 'X':
          dayNumber = 3;
          break;
        case 'J':
          dayNumber = 4;
          break;
        case 'V':
          dayNumber = 5;
          break;
        case 'S':
          dayNumber = 6;
          break;
        case 'D':
          dayNumber = 7;
          break;
      }

      let seguir = true;
      let today = new Date(new Date().setHours(0, 0, 0, 0));
      let addDays = 0;
      let lop = 0;
      while (seguir) {
        let d = new Date(today);

        if (this.companyConf.maxHourOrder > (new Date()).getHours() && lop == 0 && dayNumber ==  (new Date()).getDay()) {
          // d.setDate(d.getDate() + (((dayNumber + addDays - d.getDay()) ) || 7));
          lop = 1;
        } else {
          d.setDate(d.getDate() + (((dayNumber + addDays - d.getDay()) ) || 7));
        }

        const diferenciaEntreDias = (d.getTime() - today.getTime()) / (1000 * 3600 * 24);

        if ((diferenciaEntreDias <= this.companyConf.diasEntregaParticulares && diferenciaEntreDias > 0)
          || (diferenciaEntreDias == 0 && this.companyConf.maxHourOrder > (new Date()).getHours())) {
          dates.push(this.datePipe.transform(d, 'yyyy-MM-dd'));
          addDays += 7;
        } else if (diferenciaEntreDias < 0) {
          addDays += 7;
        } else {
          seguir = false;
        }
      }
    });

    const holiday = this.companyConf.holidays.findIndex(el => el == d1);
    return dates.find(el => d1 == el) != null && holiday == -1;
  }

  selectPickupCalendarDate(event) {
    const d1: Date  = event.value;
    if (d1 != null) {
      this.pickUpStore(new Default(this.lettersDays[d1.getDay()], this.days[d1.getDay()] + this.datePipe.transform(d1, ' dd-MM-YYYY')));
      this.deliveryDate = new FormGroup({
        deliveryDate: new FormControl(''),
      });
      this.isInRoute = 1;
    }
  }

  seleccionarFechaEntrega(event) {
    const d1: Date  = event.value;
    if (d1 != null) {
      this.selectedStorePickupDate = new Default(this.lettersDays[d1.getDay()], this.days[d1.getDay()] + ' ' + this.datePipe.transform(d1, 'dd-MM-YYYY'));
    }
  }

  initialDirectionsValues() {
    this.directionForm.controls['country'].setValue('ES', {onlySelf: true});
    if (this.userInfo == null) {
      const postalCode = this.authStorageService.getCp();
      this.directionForm.controls['province'].setValue(postalCode.substr(0, 2), {onlySelf: true});
      this.directionForm.controls['city'].setValue(postalCode.substr(2, 3), {onlySelf: true});
    } else {
      this.cookieCp = false;
    }
    this.getProvinces();
  }

  getProvinces() {
    const country = this.countries.find(e => e.id === this.directionForm.controls['country'].value);
    if (country != null) {
      this.provinces = country.provinces;
    }

    if (!this.cookieCp) {
      if (this.provinces.find(e => e.id === '28') != null) {
        this.directionForm.controls['province'].setValue('28', {onlySelf: true});
      } else {
        this.directionForm.controls['province'].setValue(this.provinces[0].id, {onlySelf: true});
      }
    }
    this.getCities();
  }

  getCities() {
    if (!this.cookieCp) {
      this.directionForm.controls['city'].setValue('default', {onlySelf: true});
    } else {
      this.cookieCp = true;
    }

    const province = this.provinces.find(e => e.id === this.directionForm.controls['province'].value);
    if (province != null) {
      this.cities = province.cities;
    }
    this.msgInfoDays = null;
  }

  getDirections() {
    if (this.userInfo != null) {
      this.authService.getUserDirections().subscribe(
        (data: Array<Direction>) => {
          this.directions = data;
          if (this.directions.length > 0) {

            if (!this.companyConf.puntosRecogida) {
              this.isInRoute = this.directions[0].isInRoute;
            }
            this.deliveryCalendarDays = this.directions[0].deliveryDays;
          } else {
            this.deliveryCalendarDays = this.userInfo.deliveryDays;
          }
        }
      );
    }
  }

  selectDirectionFn() {
    if (this.selectedDirection == null && this.directions.length === 0) {
      this.selectedDirection = new Direction(this.userInfo.country, this.userInfo.countryName,
        this.userInfo.province, this.userInfo.provinceName, this.userInfo.city,
        this.userInfo.cityName, this.userInfo.direction, this.userInfo.phone,
        this.userInfo.deliveryDays, this.userInfo.isInRoute);

      if (!this.companyConf.puntosRecogida) {
        this.isInRoute = this.userInfo.isInRoute;

      }
      this.deliveryCalendarDays = this.userInfo.deliveryDays;
    } else if (this.selectedDirection == null) {
      this.selectedDirection = new Direction(this.directions[0].country, this.directions[0].countryName,
        this.directions[0].province, this.directions[0].provinceName, this.directions[0].city,
        this.directions[0].cityName, this.directions[0].direction, this.directions[0].phone,
        this.directions[0].deliveryDays, this.directions[0].isInRoute);
      this.deliveryCalendarDays = this.directions[0].deliveryDays;
      if (!this.companyConf.puntosRecogida) {
        this.isInRoute = this.directions[0].isInRoute;
      }
    }
    this.showDirections = true;
  }

  selectPostalDirection() {
    if (this.userInfo.isInRoute == 1) {
      this.selectedDirection = new Direction(this.userInfo.country, this.userInfo.countryName,
        this.userInfo.province, this.userInfo.provinceName, this.userInfo.city,
        this.userInfo.cityName, this.userInfo.direction, this.userInfo.phone, this.userInfo.deliveryDays, this.userInfo.isInRoute);
      this.showDirections = false;
      if (!this.companyConf.puntosRecogida) {
        this.isInRoute = this.userInfo.isInRoute;
      }
      this.deliveryCalendarDays = this.userInfo.deliveryDays;
      this.recalculateDeliveryPrices(this.userInfo.priceRoute);
      this.editDirection = false;
    } else {
      this.editDirection = true;
    }
  }

  selectNewDirection(direction: Direction) {
    this.selectedDirection = new Direction(direction.country, direction.countryName,
      direction.province, direction.provinceName, direction.city,
      direction.cityName, direction.direction, direction.phone, direction.deliveryDays, 1);
    this.showDirections = false;
    this.isInRoute = 1;
    this.deliveryCalendarDays = direction.deliveryDays;
    this.recalculateDeliveryPrices(direction.priceRoute);
    this.editDirection = false;
  }

  notDefaultOption(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.directionForm != null) {
        if (control.value !== 'default') {
          return null;
        }
      }
      return {'defaultOption': {value: control.value}};
    };
  }

  setMsg() {
    const id = this.directionForm.controls['city'].value;
    this.msgInfoDays = null;
    if (id !== 'default') {
      const pos = this.cities.findIndex(x => x.id === id);
      if (pos != -1) {
        if (this.cities[pos].deliverySchedule != null && this.cities[pos].deliverySchedule != '') {
          this.msgInfoDays = 'Horario: <b>' + this.cities[pos].deliverySchedule + '</b>';
        }
      }
    }
  }

  addDirection() {
    this.errorDirection = null;
    if (this.directionForm.controls['phoneNumber'].value === '') {
      this.directionForm.controls['phoneNumber'].setValue(null, {onlySelf: true});
    }

    const country = this.countries.find(e => e.id === this.directionForm.controls['country'].value);
    const province = this.provinces.find(e => e.id === this.directionForm.controls['province'].value);
    const city = this.cities.find(e => e.id === this.directionForm.controls['city'].value);

    if (this.userInfo != null) {
      this.authService.newDirection(this.directionForm.controls['country'].value, this.directionForm.controls['province'].value,
        this.directionForm.controls['city'].value,
        this.directionForm.controls['direction'].value, this.directionForm.controls['phoneNumber'].value).subscribe(
        (data: DirectionText) => {
          this.selectedDirection = new Direction(this.directionForm.controls['country'].value,
            country.name,
            this.directionForm.controls['province'].value,
            province.name,
            this.directionForm.controls['city'].value,
            city.name,
            this.directionForm.controls['direction'].value,
            this.directionForm.controls['phoneNumber'].value,
            data.deliveryDays, 1);
          this.isInRoute = 1;
          this.deliveryCalendarDays = data.deliveryDays;
          this.directions.push(this.selectedDirection);
          this.showDirections = false;
          this.newDirection = false;

          this.recalculateDeliveryPrices(data.priceRoute);
        },
        err => {
          if (err.error.error === 'La dirección introducida ya existe.') {
            this.errorDirection = 'La dirección introducida ya existe.';
          }
        }
      );
    } else {
      this.selectedDirection = new Direction(this.directionForm.controls['country'].value,
        country.name,
        this.directionForm.controls['province'].value,
        province.name,
        this.directionForm.controls['city'].value,
        city.name,
        this.directionForm.controls['direction'].value,
        this.directionForm.controls['phoneNumber'].value, null, 1);
      this.directionValid = true;

      this.recalculateDeliveryPrices(city.priceRoute);
    }
  }

  recalculateDeliveryPrices(priceRoute: PriceRoute) {
    let sumKg = 0;
    for (const article of this.articles) {
      if (parseInt(article.format) == 1) {
        sumKg += article.quantity;
      }
    }
    let totalP = (((this.totalPrice - this.totalTransport + Number.EPSILON) * 100) / 100).toFixed(2);
    this.totalPrice = parseFloat(totalP);
  }

  makeOrder(addingOrder) {
    let modalReference = this.modalService.open(addingOrder, {backdropClass: 'light-grey-backdrop', centered: true, backdrop : 'static'});
    if (this.selectedStorePickupDate != null) {
      const date1 = this.selectedStorePickupDate.value.split(' ')[1];
      const date2 = date1.split('-');
      this.dateStr = date2[2] + '-' + date2[1] + '-' + date2[0];
    } else if (this.companyConf.deliveryCalendar) {
      this.scheduleHour = null;
    }

    const articlesOrder = this.prepareArticles();

    let direction;
    if (this.selectedDirection != null && this.selectedStorePickupDate == null) {
      direction = this.selectedDirection;
    } else if (this.directions.length > 0 && this.selectedStorePickupDate == null) {
      direction = new Direction(this.directions[0].country, this.directions[0].countryName,
        this.directions[0].province, this.directions[0].provinceName, this.directions[0].city,
        this.directions[0].cityName, this.directions[0].direction, this.directions[0].phone,
        this.directions[0].deliveryDays ,this.directions[0].isInRoute);
    } else {
      direction = new Direction(null, null, null, null, null, null,
        null, null, null, null);
    }

    let name = null;
    if  (this.contactForm.controls['name'].value + this.contactForm.controls['surnames'].value !== '') {
      name = this.contactForm.controls['name'].value + ' ' + this.contactForm.controls['surnames'].value;
    }

    let email = this.contactForm.controls['email'].value;
    if (this.userInfo) {
      email = this.userInfo.email;
    }

    let phone = this.contactForm.controls['phoneNumber'].value;
    if  (this.userInfo != null) {
      phone = direction.phone;
      if (phone === '') {
        phone = null;
      }
    } else if (phone === '') {
      phone = null;
    }

    let observations = this.observations.controls['observations'].value;
    if (observations === '') {
      observations = null;
    }

    let commercialObservations = this.observations.controls['commercialObservations'].value;
    if (commercialObservations === '') {
      commercialObservations = null;
    }

    let referenceOrder = this.observations.controls['referenceOrder'].value;
    if (referenceOrder === '') {
      referenceOrder = null;
    }

    this.shoppingCarService.makeOrder(name,
      direction.direction,
      direction.province, direction.city, direction.country, observations,
      commercialObservations, referenceOrder,
      articlesOrder, phone, this.dateStr, this.storePickup, this.scheduleHour,
      email, this.totalTransport, this.usuMod, false).subscribe(
        (data: OrderResult) => {
          modalReference.close();
          this.orderResult = data;
          if (this.userInfo == null) {
            this.localStorageService.removeAllArticles();
          }
          this.showSuccessOrder = true;
          this.finishOrder.emit(true);
        },
        (error) => {
          console.log(error);
          modalReference.close();
        }
    );
  }

  paymentOrder(addingOrder) {
    let modalReference = this.modalService.open(addingOrder, {backdropClass: 'light-grey-backdrop', centered: true, backdrop : 'static'});
    if (this.selectedStorePickupDate != null) {
      const date1 = this.selectedStorePickupDate.value.split(' ')[1];
      const date2 = date1.split('-');
      this.dateStr = date2[2] + '-' + date2[1] + '-' + date2[0];
    } else {
      this.scheduleHour = null;
    }

    const articlesOrder = this.prepareArticles();

    let email = this.contactForm.controls['email'].value;
    if (this.userInfo) {
      email = this.userInfo.email;
    }

    let direction;
    if (this.selectedDirection != null && !this.storePickup) {
      direction = this.selectedDirection;
    } else {
      direction = new Direction(null, null, null, null, null, null,
        null, null, null, null);
    }

    let name = null;
    if  (this.contactForm.controls['name'].value + this.contactForm.controls['surnames'].value !== '') {
      name = this.contactForm.controls['name'].value + ' ' + this.contactForm.controls['surnames'].value;
    }

    let phone = this.contactForm.controls['phoneNumber'].value;
    if  (this.userInfo != null) {
      phone = direction.phone;
      if (phone === '') {
        phone = null;
      }
    } else if (phone === '') {
      phone = null;
    }

    let observations = this.observations.controls['observations'].value;
    if (observations === '') {
      observations = null;
    }

    if (this.ruta == 0) {
      this.ruta = null;
    }

    let descuento = 0;
    if (this.userInfo != undefined && this.companyConf != undefined) {
      if (this.companyConf.articuloDescuento != undefined && this.companyConf.articuloDescuento != "" && this.userInfo.pendientePago < 0) {
        descuento -= this.userInfo.pendientePago;
      }
    }

    this.shoppingCarService.pendingOrder(name, direction.direction, direction.province, direction.city, direction.country, observations,
      articlesOrder, phone, this.dateStr, this.storePickup, this.scheduleHour, email, this.totalTransport, this.ruta, descuento).subscribe(
       (data: any) => {
         modalReference.close();
         this.tpvParameters = data;
         if (this.tpvParameters.paycomet_url == null) {
           if (this.tpvParameters.ceca) {
             // @ts-ignore
             timer(150).subscribe(() => document.getElementById('redirectFormCeca').submit());
           } else {
             // @ts-ignore
             timer(150).subscribe(() => document.getElementById('redirectForm').submit());
           }
         }
      },
      (error: any) => {
         console.log(error);
        modalReference.close();
      }

    );
  }

  prepareArticles() {
    const articlesOrder: Array<ArticleOrder> = [];
    this.articles.forEach(e => {
      if (e.cutType === '' || e.cutName == null) {
        e.cutType = null;
        if (e.observations === '') {
          e.observations = null;
        }
      } else {
        if (e.observations == null) {
          e.observations = e.cutName;
        } else {
          e.observations = (e.cutName + '. ' + e.observations).substr(0, 60);
        }
      }

      let sg = this.superGroups.find(el => el.groups.find(el => el.id === e.group) != null);
      if (sg != null) {
        articlesOrder.push(new ArticleOrder(e.id, e.quantity, e.lumps, e.format.toString(), e.observations, e.cutType, sg.id, e.group));
      } else {
        articlesOrder.push(new ArticleOrder(e.id, e.quantity, e.lumps, e.format.toString(), e.observations, e.cutType, null, e.group));
      }
    });
    return articlesOrder;
  }

  open(content) {
    this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  selectDeliveryDate() {
    const date = this.deliveryDate.controls['deliveryDate'].value;
    if (date == null) {
      this.deliveryDateValid = false;
    } else {
      this.deliveryDateValid = true;
      this.dateStr = this.convertDate(date);
      this.showDate = this.dateStr;
      this.selectedTodayDate = this.today.getTime() == date.getTime();
    }
  }

  convertDate(str) {
    const date = new Date(str),
      month = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join('-');
  }

  pickUpStore(storePickupDate: Default) {
    this.directionValid = true;
    this.selectDirection = false;
    this.editDirection = false;
    this.showDirections = false;
    this.selectedStorePickupDate = storePickupDate;
    this.selectedDirection = null;
    this.totalPrice = parseFloat((((this.totalPrice - this.totalTransport
                                      + Number.EPSILON) * 100) / 100).toFixed(2));
    this.totalTransport = 0;
    this.selectPickUpDate = false;
  }

  validLetters(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.userForm != null) {
        if (!(control.value.includes('\''))) {
          return null;
        }
      }
      return {'validLettersIncorrect': {value: control.value}};
    };
  }

  openModal(modal) {
    this.modalService.open(modal, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  onSubmitLogin(){
    this.loginUser(this.userForm.value.user, this.userForm.value.password);
  }

  onSubmitRegister(event){
    this.loginUser(event.id, event.password);
  }

  loginUser(id: string, password: string) {
    this.lockUser = false;
    this.isLoginFailed = false;
    this.authService.login(id, password).subscribe(
      (data: UserInfo) => {
        this.authStorageService.login(data);
        this.isLoginFailed = false;
        this.getUserData.emit(true);
        this.getUserInfo();
      },
      err => {
        if (err.error.error === 'Usuario incorrecto') {
          this.isLoginFailed = true;
        }
        if (err.error.error === 'Cliente bloqueado.') {
          this.isLoginFailed = true;
          this.lockUser = true;
        }
      }
    );
  }

  getUserInfo() {
    this.authService.getUser().subscribe(
      (result: UserInfo) => {

        this.userInfo = result;
        this._cdr.detectChanges();
      }
    );
  }

  formatNameFn(id): string {
    const format = this.companyConf.companiesKgFormats.find(e => e.id === id.toString());
    let name = ' Unidades';
    if (format != null) {
      name = ' ' + format.value;
    }
    return name;
  }

  setHomeDeliveryFn(homeDelivery: boolean) {
    if (homeDelivery != this.homeDelivery) {
      this.setHomeDelivery.emit(homeDelivery);
      this.homeDelivery = homeDelivery;
    }
  }

  editClientDirection() {
    this.directionValid = false;
    this.selectPickUpDate = false;
    this.selectDirection = false;
    this.editDirection = true;

    if (this.selectedStorePickupDate != null) {
      this.selectPickUpDate = true;
      this.selectDirection = false;
      this.selectedStorePickupDate = null;
    }
  }

  addArticuloRegalo() {
    let article = {
      approxWeight: 0,
      articleImgUrl: this.articuloRegalo.articleImgUrl.toLowerCase(),
      cutName: null,
      cutType: null,
      dropDownCuts: [],
      dropDownGauge: false,
      family: null,
      format: "2",
      gaugeName: null,
      group: this.articuloRegalo.group,
      id: this.articuloRegalo.id,
      img: null,
      line: -1,
      lumps: 0,
      maximum: 0,
      minimum: 0,
      name: this.articuloRegalo.name,
      nameClient: null,
      observations: null,
      price: this.articuloRegalo.price,
      priceIva: this.articuloRegalo.priceIva,
      productImgUrl: this.articuloRegalo.productImgUrl.toLowerCase(),
      quantity: 1,
      showPriceQuantityInfo: false,
      stock: 0,
      totalPrice: this.articuloRegalo.price,
      totalPriceIva: this.articuloRegalo.priceIva
    };

    this.totalPrice += this.articuloRegalo.priceIva;

    // @ts-ignore
    this.articles.push(article);
  }

  seleccionarRuta() {
    if (this.companyConf.rutasRecogida != null) {
      this.ruta = this.companyConf.rutasRecogida[0].id;
    }
  }

  nombreRuta(): string {
    if (this.companyConf.rutasRecogida != undefined && this.ruta != undefined) {
     return this.companyConf.rutasRecogida.find(el => el.id == this.ruta).nombre;
    }
    return  null;
  }

  pagarConPaypal(clientId: string, total: number) {
    this.payPalConfig = {
      currency: "EUR",
      clientId: "AbTuYD5NJ6HwYM8mBXlLcI6RtFtui3fMwvSoN4EQwHDM2mqWS6BzoVBeEKXDZKD3PmALUdsd0eTDxti0",
      createOrder: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              reference_id: 'Nº PEDIDO',
              amount: {
                currency_code: "EUR",
                value: "9.99",
                breakdown: {
                  item_total: {
                    currency_code: "EUR",
                    value: "9.99"
                  }
                }
              },
              items: [
                {
                  name: "Enterprise Subscription",
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: "EUR",
                    value: "9.99"
                  }
                }
              ]
            }
          ]
        },
      advanced: {
        commit: "true"
      },
      style: {
        label: "paypal",
        layout: "vertical"
      },
      onApprove: (data, actions) => {
        console.log(
          "onApprove - transaction was approved, but not authorized",
          data,
          actions
        );
        actions.order.get().then(details => {
          console.log(
            "onApprove - you can get full order details inside onApprove: ",
            details
          );
        });
      },
      onClientAuthorization: data => {
        console.log(
          "onClientAuthorization - you should probably inform your server about completed transaction at this point",
          data
        );
      }
    };
  }

  pay() {
    this.showPaypalButtons = true;
  }

  back(){
    this.showPaypalButtons = false;
  }

  cambiarHorarioRecogida() {
    if (this.ruta != undefined && this.ruta != 0 && this.companyConf.rutasRecogida != undefined) {
      let ruta = this.companyConf.rutasRecogida.find(el => el.id == this.ruta);
      if (ruta != undefined) {
        if (ruta.horaEntrega != undefined && ruta.horaEntrega.trim() != '') {
          let horario = ruta.horaEntrega.split(' - ');
          if (horario.length == 2) {
            let horaInicial =  parseInt(horario[0].split(':')[0]);
            let minutosInicial =  parseInt(horario[0].split(':')[1]);
            let horaFinal =  parseInt(horario[1].split(':')[0]);
            let minutosFinal = parseInt(horario[0].split(':')[1]);

            let arrayHoras: Array<string>  = [];
            let ultimaHora = 0;
            let ultimosMinutos = 0;

            let seguir = true;
            while (seguir) {
              if (ultimaHora == horaFinal && minutosFinal == ultimosMinutos) {
                seguir = false;
              } else {
                if (ultimaHora == 0) {
                  ultimaHora = horaInicial;
                  ultimosMinutos = minutosInicial;
                } else {
                  if (ultimosMinutos == 30) {
                    ultimosMinutos = 0;
                    ultimaHora += 1;
                  } else {
                    ultimosMinutos = 30;
                  }
                }
                arrayHoras.push(String(ultimaHora).padStart(2, '0') + ':' + String(ultimosMinutos).padStart(2, '0'));
              }
            }

            this.companyConf.storePickUpSchedule = arrayHoras;
            this.scheduleHour = arrayHoras[0];
          }

        }
      }
    }
  }
}
