import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef, HostListener, ChangeDetectorRef, Inject, Renderer2
} from '@angular/core';
import {DefaultService} from '../../@core/services/default.service';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';
import {Group, SuperGroup} from '../../@core/interfaces/group';
import {Family} from '../../@core/interfaces/family';
import {Router} from '@angular/router';
import {AuthStorageService} from '../../@core/services/auth/auth-storage.service';
import {Article, BasicArticleQuantity, DeleteArticle, ProductArticles, QuantityLumpsArticle} from '../../@core/interfaces/article';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../@core/services/auth/auth.service';
import {UserInfo} from '../../@core/interfaces/user-info';
import {ShoppingCarService} from '../../@core/services/shopping-car.service';
import {MinPrice} from '../../@core/interfaces/min-price';
import {CompanyConf, CompanyInfo} from '../../@core/interfaces/company-conf';
import {Client} from '../../@core/interfaces/client';
import {Default} from '../../@core/interfaces/default';
import {PriceRoute} from '../../@core/interfaces/price-route';
import {Country} from '../../@core/interfaces/country';
import {NumberInterface} from '../../@core/interfaces/numberInterface';
import {LocalStorageService} from '../../@core/services/local-storage.service';
import {BasicArticle} from '../../@core/interfaces/article-order';
import {CarArticle} from '../../@core/interfaces/car-article';
import {Vendor} from '../../@core/interfaces/vendor';
import {cloneDeep} from 'lodash';
import {HttpClient} from '@angular/common/http';
import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {TpvResultComponent} from '../../@core/components/tpv-result/tpv-result.component';
import {SetPasswordComponent} from '../../@core/components/auth/set-password/set-password.component';
import {NewDirectionComponent} from '../../@core/components/auth/new-direction/new-direction.component';
import {DirectionsComponent} from '../../@core/components/auth/directions/directions.component';
import {EditProfileComponent} from '../../@core/components/auth/edit-profile/edit-profile.component';
import {TpvBadResultComponent} from '../../@core/components/tpv-bad-result/tpv-bad-result.component';
import {SeeArticlesComponent} from '../../@core/components/shoppingCar/see-articles/see-articles.component';
import {OrdersComponent} from '../../@core/components/auth/orders/orders.component';
import {ArticleWindowComponent} from '../../@core/components/index/article-window/article-window.component';
import {ArticlesComponent} from '../../@core/components/index/articles/articles.component';
import {InicioComponent} from '../../@core/components/pagina-estatica/inicio/inicio.component';
import {AcercaDeComponent} from '../../@core/components/pagina-estatica/acerca-de/acerca-de.component';
import {AtencionAlClienteComponent} from '../../@core/components/pagina-estatica/atencion-al-cliente/atencion-al-cliente.component';
import {WebEstaticaService} from '../../@core/services/web-estatica/web-estatica.service';
import {SeccionProductos} from '../../@core/interfaces/web-estatica/inicio';
import {Menu} from '../../@core/interfaces/web-estatica/barra-superior';
import {ModalManager} from 'ngb-modal';
import {EncuestaComponent} from '../../@core/components/pagina-estatica/encuesta/encuesta.component';
import {DOCUMENT} from '@angular/common';
import {Posicionamiento} from '../../@core/interfaces/posicionamiento';

const down = [
  group([
    query(':enter', [
      style({ height: 0, opacity: 0 }),
      animate('1s ease-in', style({ opacity:1, transform: 'perspective(200px) translateZ(0px)',}))
    ]),
  ]),
];

declare let cordova: any;
declare let window: any;
@Component({
  selector: 'app-default-container',
  templateUrl: './default-container.component.html',
  styleUrls: ['./default-container.component.scss'],
  animations: [
    trigger('animSlider', [
      transition(':increment , :decrement', down),
    ])
  ],
})
export class DefaultContainerComponent implements OnInit {

  routeUrl = 'ofertas';
  loadedInitialValues = false;
  languages: Array<Default>;
  selectedLanguage = 'Español';
  superGroups: Array<SuperGroup>;
  families: Array<Family> = [];
  countries: Array<Country> = [];
  loadCountries = false;
  articles: Array<Article> = [];
  searchArticles: Array<Article>;
  productArticles: Array<ProductArticles> = [];
  searchArticlesText: string;
  component: SeeArticlesComponent;

  userInfo: UserInfo;
  userInfoReady = false;
  isUser = false;
  paymentMethod = 'CA';
  minPrice: number;
  priceRoute: PriceRoute = {  deliveryKg: 0, deliveryPrice: 0, fixedPrice: 0, minKg: 0};

  companyConf: CompanyConf;
  datosLegalesEmpresa: CompanyInfo;
  datosBasicos: CompanyInfo;
  mobileInit = false;
  notNeedArticles = false;

  textos = [];//["Compra en camino, descarga la app", "Oferta de la semana 5% en frutas"];
  counter: number = 0;

  @ViewChild('COMPANY') templateCompany: TemplateRef<any>;
  @ViewChild('ErrorCodigoPostal') errorPostalModal: any;
  private modalRef: any;

  cpForm = new FormGroup({
    cp: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{5}$')]),
  });
  cpNumber;
  clientForm = new FormGroup({
    client: new FormControl('', [Validators.required]),
  });
  clients: Array<Client>;
  clientsCp: Array<Client>;

  selectKgs: Array<number> = [];
  selectUnits: Array<number> = [];
  activateMobil: number; // 0 -> init; 1 -> car; 2 -> Mi Profile; 3 -> no activate
  navigationRoute;
  processOrderNav: boolean;

  numberArticles = 0;

  loadedGroups = ['ofertas'];
  loadingNewArticles = true;

  startTopArticles = false;
  listArticlesNavigation = false;
  arrayListArticles;
  shoppingCarArticlesCp: Array<CarArticle>;
  waitAddArticles = false;

  processOrder: boolean;
  defaultRoute: string;

  state;
  showOffers: boolean;
  newUser: boolean;
  innerWidth: any;
  showBottomMenu = true;
  showMenu = false;
  homeDelivery = true;
  menu2: Array<Menu> | undefined;

  publicityPhotosComputer = [];
  publicityPhotosMobile = [];
  selectedOrderSuperGroup: SuperGroup;
  posicionamiento: Posicionamiento | undefined;
  constructor(private defaultService: DefaultService, private sanitizer: DomSanitizer,
              private router: Router, private authStorageService: AuthStorageService, private modalService: ModalManager,
              private authService: AuthService, private shoppingCarService: ShoppingCarService, private httpClient: HttpClient,
              public element: ElementRef, private localStorageService: LocalStorageService, private _cdr: ChangeDetectorRef,
              private webEstaticaService: WebEstaticaService, private titleService: Title,
              private metaService: Meta, @Inject(DOCUMENT) private _document: Document, private _renderer2: Renderer2) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    this.defaultRoute = this.localStorageService.getDefaultLink();
    this.publicityImages();
    this.cargarTextos();
  }

  cargarTextos() {
    this.webEstaticaService.getBarraSuperiorJSON().subscribe(
      (data: any) => {
        this.textos = data.publicidad;
        this.menu2 = data.menu2;
        this.siguienteTexto();
      }
    );
  }

  siguienteTexto() {
    if (this.textos != undefined) {
      if (this.textos.length != 0) {
        this.sleep(5000).then(() => {
          if (this.counter == this.textos.length - 1) {
            this.counter = 0;
          } else {
            this.counter += 1;
          }
          this.siguienteTexto();
        });
      }
    }
  }

  verifyNewArticles(route: string, component: ArticlesComponent ) {
    if (this.state !== undefined) {
      this.startTopArticles = this.router.getCurrentNavigation().extras.state.topArticle;
    } else {
      this.startTopArticles = false;
    }

    if (route === '/compra/buscar') {
      this.routeUrl = 'todo';
    } else if ((this.showOffers || this.superGroups.findIndex(el => el.id == 'of') != -1) && route == '/compra/ofertas') {
      this.routeUrl = 'ofertas';
    }

    if (this.loadedGroups.find(e => e === this.routeUrl) == null && this.routeUrl != null) {
      if (this.routeUrl === 'ofertas') {
        this.getNewArticles(null, null, true, false, route, component, undefined);
        this.loadedGroups.push(this.routeUrl);
      }  else if (this.routeUrl === 'todo') {
        if (this.selectedOrderSuperGroup != null)
        {
          this.getNewArticles(this.selectedOrderSuperGroup.id, null, false, false, route, component, undefined);
          const loadedGroups = ['todo', 'ofertas'];
          let sgId = this.selectedOrderSuperGroup.id;
          if (sgId === '') {
            sgId = 'none';
          }
          loadedGroups.push(sgId + ' *');
          this.selectedOrderSuperGroup.groups.forEach(g => loadedGroups.push('none ' + g.id));
          this.loadedGroups = loadedGroups;
        } else {
          this.getNewArticles(null, null, false, true, route, component, undefined);
          const loadedGroups = ['todo', 'ofertas'];
          this.superGroups.forEach(sg => {
            let sgId = sg.id;
            if (sg.id === '') {
              sgId = 'none';
            }
            loadedGroups.push(sgId + ' *');
            sg.groups.forEach(g => loadedGroups.push('none ' + g.id));
          });
          this.loadedGroups = loadedGroups;
        }
      } else {
        this.getGroupArticles(component, route);
      }
    } else {
      this.loadingNewArticles = false;
      this.navigationRoute = route;
      if (component instanceof ArticlesComponent) {
        this.setArticlesComponent(component, route);
      }
    }
  }

  getGroupArticles(component: ArticleWindowComponent | ArticlesComponent, route: string) {
    if (this.authStorageService.getTokenType() != 2) {
      if (this.loadedGroups.find(e => e === this.routeUrl) == null && this.routeUrl != null) {
        if (this.routeUrl.split(' ')[1] === '*') {
          const superG = this.superGroups.find(el => el.id === this.routeUrl.split(' ')[0]);
          superG.groups.forEach(el => {
            if (this.loadedGroups.find(e => e === superG.id + ' ' + el.id) == null) {
              this.loadedGroups.push(superG.id + ' ' + el.id);
            }
          });
        }
        this.getNewArticles(this.routeUrl.split(' ')[0], this.routeUrl.split(' ')[1], false, false, route, component, undefined);
        this.loadedGroups.push(this.routeUrl);
      } else {
        if (component instanceof ArticleWindowComponent ) {
          this.setArticleWindowComponent(component, route);
        } else if (component instanceof ArticlesComponent ) {
          this.setArticlesComponent(component, route);
        }
      }
    } else {
      if (component instanceof ArticleWindowComponent ) {
        this.setArticleWindowComponent(component, route);
      } else if (component instanceof ArticlesComponent ) {
        this.setArticlesComponent(component, route);
      }
    }
  }

  async onChildLoaded(component:TpvResultComponent | TpvBadResultComponent | SetPasswordComponent
                                | NewDirectionComponent | DirectionsComponent | EditProfileComponent |
                                SeeArticlesComponent | OrdersComponent | ArticleWindowComponent | ArticlesComponent |
                                InicioComponent | AcercaDeComponent | AtencionAlClienteComponent | EncuestaComponent) {
    this.limpiarPosicionamiento();
    if (!(component instanceof ArticlesComponent || component instanceof  ArticleWindowComponent)) {
      this.agregarPosicionamiento();
    }

    this.topPage();
    this.component = undefined;
    if (this.listArticlesNavigation) {
      this.listArticlesNavigation = false;
      this.waitAddArticles = true;
      await this.addArticlesToShoppingCar();
      this.waitAddArticles = false;
    }
    const route = this.router.url.split('/');


    if (component instanceof TpvResultComponent) {
      this.numberArticles = 0;
      this.activateMobil = 3;
      this.notNeedArticles = true;
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Pago Correcto');
      }
    } else if (component instanceof TpvBadResultComponent) {
      this.activateMobil = 3;
      this.notNeedArticles = true;
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Error Pago');
      }
    } else if (component instanceof SetPasswordComponent) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
      component.user = this.userInfo;
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Cambiar contraseña');
      }
    } else if (component instanceof NewDirectionComponent) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
      component.countries = this.countries;

      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Nueva dirección');
      }
    } else if (component instanceof DirectionsComponent) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
      component.user = this.userInfo;
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Direcciones');
      }
    } else if (component instanceof EditProfileComponent) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
      component.user = this.userInfo;
      component.companyConf = this.companyConf;
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Perfil');
      }
    } else if (component instanceof SeeArticlesComponent) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
      component.userInfo = this.userInfo;
      component.minPrice = this.minPrice;
      component.defaultRoute = this.defaultRoute;
      component.selectedOrderSuperGroup = this.selectedOrderSuperGroup;
      component.companyConf = this.companyConf;
      component.processOrderNav = this.processOrderNav;
      if (this.priceRoute != undefined) {
        component.priceRoute = this.priceRoute;
      }
      component.selectKgs = this.selectKgs;
      component.selectUnits = this.selectUnits;
      component.indicateLumps = this.companyConf.indicateLumps;
      component.newUser = this.newUser;
      component.homeDelivery = this.homeDelivery;
      component.superGroups = this.superGroups;

      component.numberArticles = this.newArticleNumber;
      component.newPostalCode = this.newPostalCode;
      component.getUser = this.getUser;
      component.addLocalStorageArticles = this.addLocalStorageArticles;
      component.setHomeDelivery = this.setHomeDelivery;

      if (this.loadCountries) {
        component.countries = this.countries;
      } else {
        this.component = component;
      }


      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Carrito');
      }
    } else if (component instanceof OrdersComponent) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
      this.notNeedArticles = false;
      if (this.state !== undefined) {
        this.startTopArticles = this.router.getCurrentNavigation().extras.state.topArticle;
      } else {
        this.startTopArticles = false;
      }

      component.articles = this.articles;
      component.companyConf = this.companyConf;
      component.inputProductsArticles = this.productArticles;
      component.paymentMethod = this.paymentMethod;
      component.loadingNewArticles = this.loadingNewArticles;
      component.selectedOrderSuperGroup = this.selectedOrderSuperGroup;

      component.numberArticles = this.setArticleNumber;
      component.getNewArticlesGroup = this.getNewArticlesGroup;
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Pedidos');
      }
    } else if (component instanceof ArticleWindowComponent) {
      this.routeUrl = 'none ' + route[3];
      this.activateMobil = 3;

      this.getGroupArticles(component, this.router.url);
    } else if (component instanceof ArticlesComponent) {
      this.routeUrl = route[2] + ' ' + route[3];
      this.activateMobil = 3;
      this.verifyNewArticles(this.router.url, component);
    } else if (component instanceof InicioComponent) {
      this.webEstaticaService.getInicioJSON().subscribe(data => {
        if (data.fotos.length > 0) {
          component.fotoSeleccionada = data.fotos[0];
        }
        component.inicioJson = data;
        component.companyConf = this.companyConf;

        data.seccionesProductos.forEach(el => {
          if (this.loadedGroups.find(e => e === 'none ' + el.grupo) == null) {
            this.getNewArticles(null, el.grupo, false, false, '/compra/none/' + el.grupo, component, el);
          } else {
            this.agregarSeccionInicio(component, el);
          }
        });

        let groups = [];
        this.superGroups.forEach(el => el.groups.forEach(group => {
          if (group.id != 'of') {
            let g = group;
            g.superGroupId = el.id;
            groups.push(g);
          }
        }));
        component.groups = groups;
        if (this.superGroups.findIndex(el => el.id == 'of') != -1) {
          this.cargarOfertasInicio(component);
        } else {
          this.getNewArticles(null, null, true, false, '/compra/ofertas', component, undefined);
        }

        component.sleep(5000).then(() => component.nextPhoto());
      });
    } else if (component instanceof AcercaDeComponent) {
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Sobre Nosotros');
      }
    } else if (component instanceof AtencionAlClienteComponent) {
      component.companyConf = this.companyConf;
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Contacto');
      }
    } else if (component instanceof EncuestaComponent) {
      if (this.datosBasicos != undefined) {
        this.titleService.setTitle(this.datosBasicos.webName + ' | Encuesta de calidad');
      }
    }
  }


  limpiarPosicionamiento() {
    this.metaService.removeTag("name='og:title'");
    this.metaService.removeTag("name='description'");
    this.metaService.removeTag("name='og:site_name'");
    this.metaService.removeTag("name='og:url'");
    this.metaService.removeTag("name='name'");
    this.metaService.removeTag("name='author'");
    this.metaService.removeTag("name='keywords'");
    this.metaService.removeTag("name='robots'");
    this.metaService.removeTag("name='og:locale'");
    this.metaService.removeTag("name='og:type'");
    this.metaService.removeTag("name='og:image'");
    this.metaService.removeTag("name='product:price:currency'");
    this.metaService.removeTag("name='product:price:amount'");
    this.metaService.removeTag("name='product:availability'");
  }

  agregarPosicionamiento() {
    this.titleService.setTitle(this.companyConf.webName);
    if (this.posicionamiento != undefined) {
      this.metaService.addTag({ name: 'description', content: this.posicionamiento.Description });
      this.metaService.addTag({ name: 'name', content: this.posicionamiento.Name });
      this.metaService.addTag({ name: 'og:title', content: this.posicionamiento.Title });
      this.metaService.addTag({ name: 'og:locale', content: this.posicionamiento.Locale });
      this.metaService.addTag({ name: 'og:url', content: this.posicionamiento.Url });
      this.metaService.addTag({ name: 'og:site_name', content: this.posicionamiento.Name });
      this.metaService.addTag({ name: 'og:type', content: this.posicionamiento.Type });
      this.metaService.addTag({ name: 'og:image', content: this.posicionamiento.Image });
      this.metaService.addTag({ name: 'author', content: this.posicionamiento.Author });
      this.metaService.addTag({ name: 'keywords', content: this.posicionamiento.KeyWords });
      this.metaService.addTag({ name: 'robots', content: this.posicionamiento.Robots });

      let script = this._renderer2.createElement('script');
      script.type = `application/ld+json`;
      script.text = '{' +
                    '"@context": "' + this.posicionamiento.Url + '",' +
                    '"url": "' + this.posicionamiento.Url + '",' +
                    '"@type": "' + this.posicionamiento.Type + '",' +
                    '"name": "' + this.posicionamiento.Name + '",' +
                    '"headline": "' + this.posicionamiento.Title + '",' +
                    '"description": "' + this.posicionamiento.Description + '",' +
                    '"image": "' + this.posicionamiento.Image + '",' +
                    '"sameAs": [' +
                    + this.posicionamiento.SameAs +
                    '],' +
                    '"contactPoint": [' +
                    '"@type": "ContactPoint",' +
                    '"contactType" : "Atención al cliente",' +
                    '"email": "' + this.posicionamiento.Email + '",' +
                    '"phone": "' + this.posicionamiento.Phone + '",' +
                    '"url": "' + this.posicionamiento.Url + '#/atencion-al-cliente",' +
                    '],' +
                    '"publisher": "https://soft-control.net",' +
                    '"author": {' +
                    '"@type": "Organización",' +
                    '"name": "Soft Control Informática"' +
                    '}' +
                    '}';

      this._renderer2.appendChild(this._document.body, script);
    }

    try {
      const canonical: any = document.querySelectorAll('link[rel=”canonical”]');
      canonical[0].parentElement.removeChild(canonical[0]);
    } catch {}

    let link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this._document.head.appendChild(link);
    link.setAttribute('href', this._document.URL);

  }

  cargarOfertasInicio(component: InicioComponent) {
    let ofertas = [];
    this.articles.forEach(el => {
      if (el.offer) {
        ofertas.push(el)
      }
    });

    if (ofertas.length > 0) {
      component.paginasCarrousel = Math.trunc(ofertas.length / 4);
      component.articulosOferta = ofertas;
    }
  }

  agregarSeccionInicio(component: InicioComponent, seccion: SeccionProductos) {
    let articulos = this.articles.filter(el => el.group == seccion.grupo);
    component.secciones.push({
      texto: seccion.texto,
      grupo: seccion.grupo,
      paginasCarrousel: Math.trunc(articulos.length / 4),
      articulos: articulos

    });
  }

  setArticleWindowComponent(component: ArticleWindowComponent, route) {
    component.navigationRoute = route;
    component.articles = this.articles;
    component.inputArticles = this.articles;
    component.superGroups = this.superGroups;
    component.families = this.families;
    component.minPrice = this.minPrice;
    component.companyConf = this.companyConf;
    component.isUser = this.isUser;
    component.inputProductsArticles = this.productArticles;
    component.paymentMethod = this.paymentMethod;
    component.priceRoute = this.priceRoute;
    component.selectKgs = this.selectKgs;
    component.selectUnits = this.selectUnits;
    component.homeDelivery = this.homeDelivery;
    component.indicateLumps = this.companyConf.indicateLumps;

    component.numberArticles = this.newArticleNumber;
    component.newNavigationFn();
  }

  setArticlesComponent(component: ArticlesComponent, route) {
    component.navigationRoute = route;
    component.inputArticles = this.articles;
    component.inputFamilies = this.families;
    component.superGroups = this.superGroups;
    component.indicateLumps = this.companyConf.indicateLumps;
    component.startTopArticles = this.startTopArticles;
    component.minPrice = this.minPrice;
    component.companyConf = this.companyConf;
    component.priceRoute = this.priceRoute;
    component.isUser = this.isUser;
    component.inputProductsArticles = this.productArticles;
    component.paymentMethod = this.paymentMethod;
    component.isUser = this.isUser;
    component.loadingNewArticles = this.loadingNewArticles;
    component.homeDelivery = this.homeDelivery;
    component.selectKgs = this.selectKgs;
    component.selectUnits = this.selectUnits;
    component.navigationRoute = this.navigationRoute;
    component.articles = this.articles;

    component.numberArticles = this.newArticleNumber;
    component.newNavigationFn();
    this.loadQuantities(null);
  }

  setShoppingCarCp = (value: any) => {
    this.shoppingCarArticlesCp = value;
  }

  setArrayListArticles = (value: any) => {
    this.arrayListArticles  = value;
    this.addArticlesToShoppingCar();
  }

  setListArticlesNavigation = () => {
    this.listArticlesNavigation = false;
    this.numberArticles = 0;
  }

  getNewArticlesGroup = (group: string) => {
    this.getNewArticles(null, group, false, false, null, undefined, undefined);
  }

  comprobarCodigoPostal() {
    if (this.cpNumber == undefined && !this.isUser && !this.companyConf.soloRecogidaTienda) {
      this.asignarCodigoPostal();
    }
  }



  setLanguage(language: string) {
    this.authStorageService.setLanguage(language);
    window.location.reload();
  }

  inicializarInfo() {
    this.localStorageService.setShowShoppingCar(this.companyConf.showShoppingCar.toString());
    this.companyConf.logo = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + this.companyConf.logo);
    if (this.companyConf.storePickup) {
      this.homeDelivery = !this.authStorageService.storePickup();
    } else {
      this.homeDelivery = true;
    }

    if (this.companyConf.selectQuantity) {
      this.calculateSelect();
    }

    if (this.authStorageService.getTokenType() != 2) {
      this.getSuperGroups();
      this.getMinOrderPrice();
      this.getAllCountries();
    }

    if (!this.isUser) {
      this.cpNumber = this.authStorageService.getCp();
      if (this.authStorageService.getRoutePrices() != undefined) {
        this.priceRoute = this.authStorageService.getRoutePrices();
      }

      this.paymentMethod = 'CA';
      this.numberOfArticlesLocal();
    } else {
      this.getUser();
    }
  }

  getUser = () => {
    this.authService.getUser().subscribe(
      (result: UserInfo) => {
        this.authStorageService.setDiscount(result.descuento);
        this.authStorageService.setPendientePago(result.pendientePago);
        this.userInfo = result;
        this.priceRoute = result.priceRoute;
        this.authStorageService.setRoutePrices(this.priceRoute);
        if (!this.homeDelivery) {
          this.setInfoRoute();
        }
        this.paymentMethod = result.paymentMethod;

        this.isUser = true;
        this.newUser = true;
        this._cdr.detectChanges();
      }
    );
  }

  addLocalStorageArticles = () => {
    const articles = this.localStorageService.getLocalStorageArticles();
    let arts = [];

    if (articles != null && Array.isArray(articles)) {
      articles.forEach(el => {
        arts.push(new BasicArticle(el.id, el.format, el.quantity, el.lumps, el.cutType, el.dropDownGauge));
      })
    } else if (articles != null) {
      arts.push(new BasicArticle(articles.id, articles.format, articles.quantity, articles.lumps, articles.cutType, articles.dropDownGauge));
    }

    this.shoppingCarService.deleteAllArticles().subscribe(
      () => {
        this.shoppingCarService.addArticles(arts).subscribe(
          () => {
            this.numberArticles = arts.length;
            this._cdr.detectChanges();
          }
        );
      }
    );
    // this.shoppingCarService.addArticles(articles).subscribe();
  }

  setHomeDelivery = (value: boolean) => {
    this.homeDelivery = value;
    this.setInfoRoute();
  }


  getSuperGroups() {
    this.defaultService.getSuperGroups().subscribe(
      (data: Array<SuperGroup>) => {
        this.superGroups = data;
        this.showOffers = this.superGroups.find(el => el.groups.find(g => g.id === 'of') != null) != null;
        this.getAllFamilies();
      }
    );
  }

  getAllFamilies() {
    this.defaultService.getFamilies().subscribe(
      (data: Array<Family>) => {
        this.families = data;
        this.loadedInitialValues = true;

        // Mostrar super grupo a seleccionar
        this.selectedOrderSuperGroup = this.localStorageService.getSelectedOrderSuperGroup();
        if (this.isUser) {
          this.defaultService.getNumberOfArticles().subscribe(
            (result: NumberInterface) => {
              this.numberArticles = result.value;
              this._cdr.detectChanges();
            }
          );
        }
      }
    );
  }

  cargarTodos(){
    if (this.loadedGroups.findIndex(el => el == 'todo') == -1) {
      this.getNewArticles(null, null, false, true, 'ninguna', null, undefined);
      this.loadedGroups.push('todo');
    }
  }

  getNewArticles(superGroup: string, group, offers: boolean, all: boolean, route: string,
                 component: ArticleWindowComponent | ArticlesComponent | InicioComponent,
                 seccion: SeccionProductos) {
    this.loadingNewArticles = true;
    if (superGroup === 'none') {
      superGroup = null;
    }

    if (group === '*') {
      group = null;
    }

    if (this.companyConf.companyType == 'F') {
      this.defaultService.getNewArticles(this.authStorageService.getLanguage(), superGroup, group, offers, all).subscribe(
        (data: Array<Article>) => {
          if (this.companyConf.ponerCantidadMenuInicial && this.isUser) {
            this.shoppingCarService.getPendingArticles(null, null).subscribe(
              (data1: Array<CarArticle>) => {
                let quantities: Array<BasicArticleQuantity> = [];
                data1.forEach(el => {
                  quantities.push({id: el.id, line: el.line, quantity: el.quantity})
                });
                this.setArticlesInfo(data, quantities);


                this.userInfoReady = true;
                this.loadingNewArticles = false;
                this.loadingNewArticles = false;
                if (route != 'ninguna') {
                  this.navigationRoute = route;
                  this.cargarTodos();
                }

                this._cdr.detectChanges();

                if (component instanceof ArticleWindowComponent) {
                  this.setArticleWindowComponent(component, route);
                } else if  (component instanceof ArticlesComponent) {
                  this.setArticlesComponent(component, route);
                } else if (component instanceof InicioComponent && seccion == undefined) {
                  this.cargarOfertasInicio(component);
                } else if (component instanceof InicioComponent) {
                  this.agregarSeccionInicio(component, seccion);
                }
              }
            );
          } else{
            this.setArticlesInfo(data, null);
            this.userInfoReady = true;
            this.loadingNewArticles = false;
            this.loadingNewArticles = false;
            if (route != 'ninguna') {
              this.cargarTodos();
              this.navigationRoute = route;
            }

            this._cdr.detectChanges();
            if (component instanceof ArticleWindowComponent) {
              this.setArticleWindowComponent(component, route);
            } else if  (component instanceof ArticlesComponent) {
              this.setArticlesComponent(component, route);
            } else if (component instanceof InicioComponent && seccion == undefined) {
              this.cargarOfertasInicio(component);
            } else if (component instanceof InicioComponent) {
              this.agregarSeccionInicio(component, seccion);
            }
          }
        });
    } else if (this.companyConf.companyType === 'P') {
      this.defaultService.getNewArticles(this.authStorageService.getLanguage(), superGroup, group, offers, all).subscribe(
        (data: Array<ProductArticles>) => {
          this.userInfoReady = true;

          if (route != 'ninguna') {
            this.cargarTodos();
            this.navigationRoute = route;
          }

          // Articles data
          for (const product of data) {
            if (product.dropDownGauge === false) {
                product.articles.forEach(e => {
                  if (this.articles.find(el => el.id === e.id) == null) {
                    this.articles.push(e);
                  }
                });
            } else {
              let offer = 0;
              if (product.articles.find(e => e.offer === 1) != null) {
                offer = 1;
              }
              const art = new Article('PRODUCT-' + product.id , product.name, null, product.articles[0].realFormatName,
                  null, product.productImgUrl, product.img,
                0, 0, product.name, product.group, product.groupName, product.family, product.familyName, offer, product.superGroup,
                1, null, null, false, 0, 0 , 0, null);
              if (this.articles.find(el => el.id === art.id) == null) {
                this.articles.push(art);
              }

            }
          }

          data.forEach(el => {
            el.articles.sort((a,b)=> (a.maximum > b.maximum) ? 1 : -1);
            if (this.productArticles.find(p => p === el) == null) {
              this.productArticles.push(el);
            }
          });

          this.loadingNewArticles = false;
          this.navigationRoute = route;
          this._cdr.detectChanges();

          if (component instanceof ArticleWindowComponent) {
            this.setArticleWindowComponent(component, route);
          } else if  (component instanceof ArticlesComponent) {
            this.setArticlesComponent(component, route);
          } else if (component instanceof InicioComponent && seccion == undefined) {
            this.cargarOfertasInicio(component);
          } else if (component instanceof InicioComponent) {
            this.agregarSeccionInicio(component, seccion);
          }
        });
    }
  }

  setArticlesInfo(data: Array<Article>, quantities: Array<BasicArticleQuantity>) {
    const articles: Array<Article> = data;
    for (let i = 0; i < data.length; i++) {
      if (this.articles.find(el => el.id == articles[i].id) == null) {
        this.articles.push(articles[i]);
      }
    }

    this.loadQuantities(quantities);
  }

  loadQuantities(quantities) {
    if (!this.isUser) {
      const arts = this.localStorageService.getLocalStorageArticles();
      if (arts != undefined) {
        this.articles.forEach(el =>  {
          let art = undefined;
          if (arts instanceof Array) {
            art = arts.find(a => a.id == el.id);
          } else {
            art = arts;
          }

          if (art != undefined) {
            if(el.id == art.id) {
              el.quantity = parseFloat(art.quantity);
            }
          }
        });
      }
    } else {
      if (quantities != undefined) {
        if (this.articles != undefined) {
          this.articles.forEach(el =>  {
            let art = quantities.find(a => a.id == el.id);
            if (art != undefined) {
              if(el.id == art.id) {
                el.quantity = art.quantity;
                el.line = art.line;
              }
            }
          });
        }
      } else {
        this.shoppingCarService.getPendingArticles(null, null).subscribe(
          (data1: Array<CarArticle>) => {

            let quantities: Array<BasicArticleQuantity> = [];
            if (data1 != undefined) {
              data1.forEach(el => {
                quantities.push({id: el.id, line: el.line, quantity: el.quantity})
              });
              this.articles.forEach(el =>  {
                let art = quantities.find(a => a.id == el.id);
                if (art != undefined) {
                  if(el.id == art.id) {
                    el.quantity = art.quantity;
                    el.line = art.line;
                  }
                }
              });
            }
          }
        );
      }
    }
  }

  getAllCountries() {
    this.defaultService.getAllDirections().subscribe(
      (data: Array<Country>) => {
        this.countries = data;
        this.loadCountries = true;
        if (this.component != undefined) {
          this.component.countries = this.countries;
        }

        this.asignarCodigoPostal();
      }
    );
  }

  goShoppingCar() {
    this.redirectTo('/carrito');
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
  }

  redirectToFinishOrder() {
    this.router.navigateByUrl('/carrito', { state: { processOrder: true } });
  }

  redirectToHome() {
    if (this.companyConf.paginaEstatica) {
      this.router.navigateByUrl('/inicio').then();
    } else {
      this.router.navigateByUrl('/compra/' + this.superGroups[0].id + '/' + this.superGroups[0].groups[0].id).then();
    }
  }

  checkUser() {
    this.isUser = this.authStorageService.getUser() != null;
  }

  setSearchArticles() {
    this.searchArticles = undefined;
    if (this.searchArticlesText != undefined && this.searchArticlesText.trim() != '') {
      let splitText = this.searchArticlesText.toLowerCase().split(' ');
      this.searchArticles = this.articles.filter(a => {
        let include = true;
        splitText.forEach(text => {
          if (a.nameClient != undefined) {
            if (!a.name.toLowerCase().includes(text.toLowerCase().trim()) && !a.nameClient.toLowerCase().includes(text.toLowerCase().trim())) {
              include = false;
            }
          } else if (!a.name.toLowerCase().includes(text.toLowerCase().trim())) {
            include = false;
          }
        });
        return include;
      });

    }
  }

  logOut() {
    this.authStorageService.logOut();
    this.localStorageService.setSelectedOrderSuperGroup(null);
    this.userInfo = null;
    this.isUser = false;
    this.router.navigateByUrl('/iniciarSesion');
  }

  asignarCodigoPostal() {
    const country = this.countries.find(e => e.id === 'ES');
    if (country != null) {
      console.log(this.companyConf.codigoPostal);
      console.log(this.companyConf.codigoPostal.substr(0, 2));
      console.log(this.companyConf.codigoPostal.substr(2, 3));
      const province = country.provinces.find(e => e.id === this.companyConf.codigoPostal.substr(0, 2));
      if (province != null) {
        const city = province.cities.find(e => e.id === this.companyConf.codigoPostal.substr(2, 3));
        if (city != null) {
          this.priceRoute = city.priceRoute;
          this.setCp();
          return;
        }
      }
    }
  }

  openModal(modal) {
    this.modalService.open(modal, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  openModalXL(modal) {
    this.modalService.open(modal, {backdrop: 'static', backdropClass: 'light-grey-backdrop', centered: true, size: 'xl'});
  }

  setCp() {
    this.cpNumber = this.companyConf.codigoPostal;
    this.authStorageService.setCp(this.cpNumber);
    this.authStorageService.setRoutePrices(this.priceRoute);
  }

  getMinOrderPrice() {
    this.shoppingCarService.getMinOrderPrice().subscribe(
      (data: MinPrice) => {
        this.minPrice = data.price;
    });
  }

  getArticlesSuperGroup(superGroup: SuperGroup) {
    if (superGroup.groups.length === 1) {
      this.redirectTo('/compra/' + superGroup.id + '/' + superGroup.groups[0].id );
    }
  }

  getArticlesSuperGroupGroup(superGroup: SuperGroup, group: Group) {
    this.redirectTo('/compra/' + superGroup.id + '/' + group.id );
  }

  getArticles(group: Group) {
    this.redirectTo('/compra/none/' + group.id );
  }

  cerrarModal() {
    this.modalRef.close();
  }

  newPostalCode = () => {
    this.cpNumber = this.authStorageService.getCp();
    if (this.authStorageService.getRoutePrices() != undefined) {
      this.priceRoute = this.authStorageService.getRoutePrices();
    }
  }

  calculateSelect() {
    this.selectKgs.push(this.companyConf.limits.minKg);
    this.selectUnits.push(this.companyConf.limits.minUnit);
    if (this.companyConf.limits.minKg < this.companyConf.limits.maxKg) {
      let total = this.companyConf.limits.minKg;
      while (total <= this.companyConf.limits.maxKg) {
        total += this.companyConf.limits.intervalKg;
        if (total <= this.companyConf.limits.maxKg) {
          this.selectKgs.push(total);
        }
      }
    }

    if (this.companyConf.limits.minUnit < this.companyConf.limits.maxUnit) {
      let total = this.companyConf.limits.minUnit;
      while (total <= this.companyConf.limits.maxUnit) {
        total += 1;
        if (total <= this.companyConf.limits.maxUnit) {
          this.selectUnits.push(total);
        }
      }
    }
  }

  selectMenuActive(init: Element, car: Element, profile: Element) {
    if (this.activateMobil === 0) {
      init.className = 'active';
      car.className = null;
      profile.className = null;
    } else if (this.activateMobil === 1) {
      car.className = 'active';
      init.className = null;
      profile.className = null;
    } else if (this.activateMobil === 2 || this.activateMobil === 4) {
      profile.className = 'active';
      init.className = null;
      car.className = null;
    } else {
      init.className = null;
      car.className = null;
      profile.className = null;
    }
    this.activateMobil = null;
  }

  topPage() {
    window.scroll(0, 0);
  }

  newArticleNumber = (number: number) => {
    this.numberArticles = number;
  }

  numberOfArticlesLocal() {
    this.numberArticles = 0;
    const arts = this.localStorageService.getLocalStorageArticles();
    let articlesCookies: Array<CarArticle> = null;
    if (arts != null && Array.isArray(arts)) {
      articlesCookies = arts;
    } else if (arts != null) {
      articlesCookies = [arts];
    }

    if (articlesCookies != null) {
      let articlesStr = '';
      articlesCookies.forEach( e => {articlesStr += "'" + e.id + "'" + ','});
      articlesStr = articlesStr.substr(0, articlesStr.length - 1);
      if (articlesStr != '') {
        this.shoppingCarService.getPendingArticles(articlesStr, null).subscribe(
          (data: Array<CarArticle>) => {
            if (data != undefined) {
              this.numberArticles = data.length;
            }
          }
        );
      }
    }
  }

  setArticleNumber = () => {
    this.defaultService.getNumberOfArticles().subscribe(
      (result: NumberInterface) => {
        this.numberArticles = result.value;
      }
    );
  }

  async  addArticlesToShoppingCar() { // se añaden todos los articulos al carrito
    if (this.arrayListArticles == undefined || this.shoppingCarArticlesCp == undefined) {return;}
    const articles: Array<BasicArticle> = [];
    const articlesDelete: Array<DeleteArticle> = [];
    const articlesSetQuantity: Array<QuantityLumpsArticle> = [];
    let numOfArticles = 0;
    this.arrayListArticles.forEach( el => { // Filtramos los articulos
        el[1].forEach( art => {
          art.forEach( article => {
            if (article.cut === '') article.cut = null;
            this.shoppingCarArticlesCp.forEach(a => {if (a.cutType === '') article.cutType = null});

            const shArticle = this.shoppingCarArticlesCp.find(s => s.id === article.id && s.cutType == article.cut);
            if (article.quantity === 0 && article.lumps == null && article.format === '1' && this.companyConf.onlyUnits) {
              article.quantity = null;
            }

            if (article.quantity != null || (article.lumps != null && this.companyConf.onlyUnits)) {
              if (this.companyConf.onlyUnits && article.format === '1') {
                if (shArticle != null) { // Cambiar cantidad
                  if (shArticle.lumps !== article.lumps) { // Cambiar cantidad
                    articlesSetQuantity.push(new QuantityLumpsArticle(article.id, article.format, shArticle.line, article.quantity, article.lumps));
                  }
                } else { // Añadir nuevo articulo
                  articles.push(new BasicArticle(article.id, article.format.toString(), article.quantity, article.lumps, article.cut, false));
                }
              } else if (this.companyConf.onlyUnits && article.format !== '1') { // Vender en unidades
                if (shArticle != null) { // Cambiar cantidad
                  if (shArticle.lumps !== article.lumps) {
                    articlesSetQuantity.push(new  QuantityLumpsArticle(article.id, article.format, shArticle.line, article.lumps, article.lumps));
                  }
                } else { // Añadir nuevo articulo
                  articles.push(new BasicArticle(article.id, article.format.toString(), article.lumps, article.lumps, article.cut, false));
                }
              } else {
                if (shArticle != null) { // Cambiar cantidad
                  if (shArticle.quantity !== article.quantity || shArticle.format !== article.format) {
                    articlesSetQuantity.push(new  QuantityLumpsArticle(article.id, article.format, shArticle.line, article.quantity, 0));
                  }
                } else { // Añadir nuevo articulo
                  const a = new BasicArticle(article.id, article.format.toString(), article.quantity, 0, article.cut, false);
                  a.Observations = article.observations;
                  a.AddQuantity = false;
                  articles.push(a);
                }
              }

              numOfArticles ++;
            } else if (shArticle != null) { // eliminar articulo
              if (shArticle.cutType === '') shArticle.cutType = null;
              articlesDelete.push(new DeleteArticle(shArticle.id, shArticle.line.toString(), shArticle.cutType));
            }
          });
        });
      }
    );

    if (articlesDelete.length > 0) {
      await this.deletePendingArticles(articlesDelete).then();
    }

    if (articles.length > 0) {
      await this.addArticles(articles).then();
    }

    if (articlesSetQuantity.length > 0) {
      await this.setListArticlesQuantity(articlesSetQuantity).then();
    }

    this.numberArticles = numOfArticles;
    this.arrayListArticles = null;
  }

  async addArticles(articles) {
    return await this.shoppingCarService.addArticles(articles).toPromise();
  }

  async deletePendingArticles(articles) {
    return await this.shoppingCarService.deletePendingArticles(articles).toPromise();
  }

  async setListArticlesQuantity(articles) {
    return await this.shoppingCarService.setListArticlesQuantity(articles).toPromise();
  }

  newNavFn(value){
    this.processOrderNav = value;
    this._cdr.detectChanges()
  }

  getArticlesGroup(superGroup: SuperGroup, group: Group) {
    if (superGroup.id === '') {
      this.redirectTo('/compra/none/' + group.id );
    } else {
      this.redirectTo('/compra/' + superGroup.id + '/' + group.id );
    }
  }

  capitalizeWords(string: string) {
    if (string != undefined) {
      string = string.toLowerCase();
      return string.replace(/(?:^|[\s.])\S/g, function(a) { return a.toUpperCase(); });
    }
  };

  setInfoRoute() {
    if (this.homeDelivery) {
      this.authStorageService.setInfoRoute();
      if (this.authStorageService.getRoutePrices()!= undefined) {
        this.priceRoute = this.authStorageService.getRoutePrices();
      }
    } else {
      this.authStorageService.copyInfoRoute();
      this.priceRoute = new PriceRoute(0, 0, 0, 0);
    }
  }

  publicityImages() {
    this.httpClient.get('./assets/fotos/publicidad/ordenador/aa-lista-de-fotos.json').subscribe(data => {
      // @ts-ignore
      data.photos.forEach(
        (el: string) => {
          this.publicityPhotosComputer.push('./assets/fotos/publicidad/ordenador/' + el);
       }
      );

      this.httpClient.get('./assets/fotos/publicidad/movil/aa-lista-de-fotos.json').subscribe(data => {
        // @ts-ignore
        data.photos.forEach(
          (el: string) => {
            this.publicityPhotosMobile.push('./assets/fotos/publicidad/movil/' + el);
          }
        )
      });
    });
  }

  selectOrderSg(sg: SuperGroup) {
    this.localStorageService.setSelectedOrderSuperGroup(sg);
    if (this.selectedOrderSuperGroup == null) {
      this.localStorageService.setSelectedOrderSuperGroup(sg);
      this.selectedOrderSuperGroup = sg;
      this.listArticlesNavigation = true;
      this.loadingNewArticles = true;
      this.activateMobil = 3;
      this.routeUrl = 'todo';

      this.defaultService.getNumberOfArticlesOfSuperGroup(this.selectedOrderSuperGroup.id).subscribe(
        (result: NumberInterface) => {
          this.numberArticles = result.value;
          this._cdr.detectChanges();
        }
      );

      this.verifyNewArticles( this.router.url, undefined);
    } else if (this.selectedOrderSuperGroup.id != sg.id) {
      window.location.reload();
    }

  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  irARuta(ruta: string) {
    this.redirectTo(ruta);
  }

  @HostListener('window:beforeunload')
  addArticlesList() {
    if (this.listArticlesNavigation) {
      this.addArticlesToShoppingCar();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('focusin', ['$event'])
  onFocus($event) {
    const target = $event.target;
    if (target.tagName == "INPUT" && target.type == "text") {
      this.showBottomMenu = false;
    }
  }

  @HostListener('focusout', ['$event'])
  onFocusOut() {
    this.showBottomMenu = true;
  }

  openExternalLink(link: string) {
    if (window.cordova && cordova.platformId !== "browser") {
      cordova.InAppBrowser.open(link, '_system');
    } else {
      window.open(link, '_blank', 'location=yes');
    }
  }

}
